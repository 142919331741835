@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/App.css */
.my-custom-button {
    @apply bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded;  /* Tailwind classes */
}

body {
    font-family: Arial, sans-serif;
    background: #121212;
    color: #ffffff;
    margin: 0;
    padding: 0;
}

header {
    background: #1e1e1e;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header img {
    max-width: 220px; /* 25% increase in size */
}

header .tagline {
    font-size: 2em;  /* Twice the current size */
    font-style: italic; /* Italic text */
    font-family: "Courier-new", sans-serif; /* Alternative font for a sleek look */
    text-align: center; /* Ensures text is centered */

    font-size: 40px;
    font-weight: bold;
    color: #00bcd4;
    text-align: center;
    flex-grow: 1;
}

nav {
    background: #252525;
    padding: 15px 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: #00bcd4;
    text-decoration: none;
    font-size: 18px;
}

nav ul li a:hover {
    color: #ffffff;
}

section {
    padding: 40px;
    background: #1e1e1e;
    margin: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    flex-direction: row; /* All images aligned to the left */
}

section img {
    max-width: 12.5%;
    border-radius: 10px;
    margin-right: 20px;
}

#contact img {
    max-width: 13%; /* Contact image now 50% of its previous size */
}

h2 {
    color: #00bcd4;
}

.section-content {
    width: 100%;
}

.section-content p {
    margin-bottom: 10px;
}

.section-content ul {
    list-style-type: disc;
    margin-left: 40px;
}

footer {
    background: #252525;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
}

html {
    scroll-padding-top: 80px; /* Adjust this value to leave space above sections */
    scroll-behavior: smooth;
}
